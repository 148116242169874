<template>
  <b-card-code
    no-body
    title="Block Trade List"
  >

    <b-modal
      id="detail-modal"
      no-close-on-backdrop
      size="lg"
      title="Detail"
    >

      <table
        v-if="detail.uuid != null"
        class="table table-hover"
      >
        <tr>
          <td class="ml-0 pl-0">
            Trade Number
          </td>
          <td>{{ detail.trade_number }}</td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Commodity
          </td>
          <td>{{ typeof detail.commodity !== undefined ? detail.commodity.code : '' }}</td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Warehouse
          </td>
          <td>{{ typeof detail.warehouse !== undefined ? detail.warehouse.name : '' }}</td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Seller
          </td>
          <td v-if="detail.seller != null">
            <router-link :to="{ name: 'users.show', params: { id: detail.seller.id } }">
              {{ detail.seller.code }}<br><small>{{ detail.seller.email }}</small>
            </router-link>
          </td>
          <td v-else>
            -
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Buyer
          </td>
          <td v-if="detail.buyer != null">
            <router-link :to="{ name: 'users.show', params: { id: detail.buyer.id } }">
              {{ detail.buyer.code }}<br><small>{{ detail.buyer.email }}</small>
            </router-link>
          </td>
          <td v-else>
            -
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Price
          </td>
          <td>{{ detail.price_formatted }}</td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Date
          </td>
          <td>{{ detail.date }}</td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Loading Port
          </td>
          <td>{{ detail.loading_port }}</td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Submission Time
          </td>
          <td>{{ detail.created_at }}</td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Verified by Seller (via OTP)
          </td>
          <td>
            <div v-if="detail.verified_by_seller_at != null">
              <feather-icon
                icon="CheckCircleIcon"
              />
              at {{ detail.verified_by_seller_at }}
            </div>
            <div v-else>
              <feather-icon
                icon="XCircleIcon"
              />

              <button v-if="checkPermission('resend otp block trade') && detail.status_admin == 'pending'" class="btn btn-sm btn-warning ml-1" @click="resendOtp(detail, 'seller')">Resend OTP to Seller</button>
            </div>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Verified by Buyer (via OTP)
          </td>
          <td>
            <div v-if="detail.verified_by_buyer_at != null">
              <feather-icon
                icon="CheckCircleIcon"
              />
              at {{ detail.verified_by_buyer_at }}
            </div>
            <div v-else>
              <feather-icon
                icon="XCircleIcon"
              />

              <button v-if="checkPermission('resend otp block trade') && detail.status_admin == 'pending'" class="btn btn-sm btn-warning ml-1" @click="resendOtp(detail, 'buyer')">Resend OTP to Buyer</button>
            </div>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">
            Verified by Admin
          </td>
          <td>
            <p class="text-capitalize">
              <strong>{{ detail.status_admin }}</strong>
            </p>
            <div v-if="detail.status_admin != 'pending'">
              at {{ detail.status_admin_updated_at }}
              {{ detail.status_admin_updated_by != null ? ' by ' + detail.approved_by_admin.name : '' }}
            </div>
          </td>
        </tr>
      </table>

      <br>

      <table class="table">
        <tr>
          <th>CDN Number</th>
          <th>Weight</th>
          <th>Price</th>
          <th>Net Value</th>
        </tr>
        <tr
          v-for="(detailItem, index) in detail.items"
          :key="index"
        >
          <td>{{ detailItem.bst.cdn_number }}</td>
          <td>{{ detailItem.qty_format }}</td>
          <td>{{ detailItem.price_format }}</td>
          <td>{{ detailItem.net_value_format }}</td>
        </tr>
        <tr>
          <td><strong>Total</strong></td>
          <td><strong>{{ detail.qty_formatted }}</strong></td>
          <td><strong>{{ detail.price_formatted }}</strong></td>
          <td><strong>{{ detail.net_value_formatted }}</strong></td>
        </tr>
      </table>

      <template
        v-if="detail.status_admin == 'pending'"
        #modal-footer="{}"
      >
        <b-button
          v-if="checkPermission('reject block trade')"
          variant="danger"
          @click="reject(detail)"
        >
          Reject
        </b-button>
        <b-button
          v-if="checkPermission('approve block trade')"
          variant="success"
          @click="approve(detail)"
        >
          Approve
        </b-button>
      </template>
    </b-modal>
    <br>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>Trade Number</th>
            <th>Commodity & Warehouse</th>
            <th>Seller</th>
            <th>Buyer</th>
            <th>Price</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td
              colspan="7"
              class="text-center"
            >
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr
            v-for="item in result.data"
            v-else
            :key="item.id"
          >
            <td>{{ item.trade_number }}</td>
            <td>{{ typeof item.commodity !== undefined ? item.commodity.code : '' }} - {{ typeof item.warehouse !== undefined ? item.warehouse.name : '' }}</td>
            <td v-if="item.seller != null">
              <router-link :to="{ name: 'users.show', params: { id: item.seller.id } }">
                {{ item.seller.code }}<br><small>{{ item.seller.email }}</small>
              </router-link>
            </td>
            <td v-else>
              -
            </td>
            <td v-if="item.buyer != null">
              <router-link :to="{ name: 'users.show', params: { id: item.buyer.id } }">
                {{ item.buyer.code }}<br><small>{{ item.buyer.email }}</small>
              </router-link>
            </td>
            <td v-else>
              -
            </td>
            <td>{{ item.price_formatted }}</td>
            <td>
              <p class="text-capitalize">{{ item.status_admin }}</p>
            </td>
            <td>
              <div
                class="btn-group"
                role="group"
              >
                <button
                  class="btn btn-info btn-sm"
                  style="margin-left: 2px;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Detail"
                  @click="detailItem(item)"
                >
                  <feather-icon
                    icon="InfoIcon"
                  />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td
              colspan="7"
              class="text-center"
            >
              Data is empty.
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="result.total > 0"
        class="m-1"
      >
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination
              :data="result"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BButton, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  title() {
    return 'Block Trade List'
  },
  components: {
    BCardCode,
    BSpinner,
    BButton,
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        status: this.$route.query.status == null ? '' : this.$route.query.status,
        amount: '',
      },
      detail: Object,
      isLoading: false,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      this.isLoading = true
      this.currentPage = page
      const queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/blocktrades', {
        params: queryParams,
      }).then(response => {
        this.result = response.data.data
        this.isLoading = false
      })
    },
    detailItem(item) {
      this.detail = item

      this.$bvModal.show('detail-modal')
    },
    resendOtp(item, type) {
      this.$swal({
        title: 'Are you sure?',
        text: `System will resend OTP email to ${type}`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const context = this;
          context.$http.post(`/admin/blocktrades/${item.uuid}/resend-otp?type=${type}`).then(function () {
            context.$swal({
              icon: 'success',
              title: 'Approved!',
              text: 'Resend OTP success.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })
        }
      })
    },
    approve(item) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Make sure that Buyer & Seller has been verify this transaction!',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const context = this;
          context.$http.post(`/admin/blocktrades/${item.uuid}/approve`).then(function () {
            context.getData(context.currentPage)
            context.$bvModal.hide('detail-modal')
            context.$swal({
              icon: 'success',
              title: 'Approved!',
              text: 'Block Trade status changed to success.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
            this.isLoading = false;
          })
        }
      })
    },
    reject(item) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Block Trade will canceled and CDN will refunded to Seller',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          const context = this;
          context.$http.post(`/admin/blocktrades/${item.uuid}/reject`)
            .then(function(){
              context.getData(context.currentPage)
              context.$bvModal.hide('detail-modal')
              context.$swal({
                icon: 'success',
                title: 'Rejected!',
                text: 'Blocktrade status changed to rejected/failed.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
              this.isLoading = false;
            })
        }
      })
    },
  },
}
</script>
