<template>
  <b-row>
      <b-col cols="12" lg="8">
          <b-card>

            <b-row>

            <!-- User Info: Left col -->
            <b-col
                cols="21"
                xl="6"
                class="d-flex justify-content-between flex-column"
            >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                <b-avatar
                    :src="userData.photo_url"
                    :text="userData.name.charAt(0)"
                    :variant="`light-danger`"
                    size="104px"
                    rounded
                />
                <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                    <h4 class="mb-0">
                        {{ userData.name }}
                    </h4>
                      <span class="card-text text-break">{{ userData.email }}</span>
                      <small class="card-text text-break"><br>Other recipients: {{ userData.emails.join(', ') }}</small>
                    </div>
                    <div class="d-flex flex-wrap">
                        <b-dropdown
                            variant="info"
                            size="sm"
                        >
                            <template #button-content>
                            Action
                            </template>
                            <b-dropdown-item v-if="userData.approval_status == 'active' && checkPermission('suspend user')" @click="suspend(userData.id)">
                                <feather-icon
                                    icon="LockIcon"
                                    class="mr-75"
                                />
                                Suspend User
                            </b-dropdown-item>
                            <b-dropdown-item v-if="userData.approval_status == 'suspend' && checkPermission('suspend user')" @click="unsuspend(userData.id)">
                                <feather-icon
                                    icon="UnlockIcon"
                                    class="mr-75"
                                />
                                Unsuspend User
                            </b-dropdown-item>
                            <b-dropdown-item v-if="checkPermission('approve user') && userData.approval_status == 'pending'" @click="approveUser(userData.id)">
                                <feather-icon
                                    icon="CheckIcon"
                                    class="mr-75"
                                />
                                Approve User
                            </b-dropdown-item>
                            <b-dropdown-item v-if="checkPermission('reject user') && userData.approval_status == 'pending'" @click="rejectUser(userData.id)">
                                <feather-icon
                                    icon="XIcon"
                                    class="mr-75"
                                />
                                Reject User
                            </b-dropdown-item>
                            <b-dropdown-item v-if="checkPermission('delete user')" @click="deleteItem(userData.id)">
                                <feather-icon
                                    icon="TrashIcon"
                                    class="mr-75"
                                />
                                Delete User
                            </b-dropdown-item>
                            <b-dropdown-item v-if="userData.approval_status == 'active' && checkPermission('send reset password user')" @click="resetPassword(userData.id)">
                                <feather-icon
                                    icon="KeyIcon"
                                    class="mr-75"
                                />
                                Reset Password
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
                </div>

                <!-- Cash & Commodity Balance -->
                <div class="align-items-center mt-2">

                    <!-- Cash Balance -->
                    <div class="d-flex align-items-center mr-2 mt-1" v-for="cash in userData.cash_balance" v-bind:key="cash.code">
                        <b-avatar
                        variant="light-success"
                        rounded
                        >
                        <feather-icon
                            icon="DollarSignIcon"
                            size="18"
                        />
                        </b-avatar>
                        <div class="ml-1">
                        <h5 class="mb-0">
                            <!-- {{ cash.balance_formatted }} -->
                            Fund Balance
                        </h5>
                        <small>
                            Cash Balance: <strong>{{ cash.liquid_balance_formatted }}</strong><br>
                            Bank Guarantee: <strong>{{ cash.collateral_balance_formatted }}</strong>
                        </small>
                        </div>
                    </div>

                    <div class="d-flex align-items-center mr-2 mt-1">
                        <b-avatar
                        variant="light-success"
                        rounded
                        >
                        <feather-icon
                            icon="CalendarIcon"
                            size="18"
                        />
                        </b-avatar>
                        <div class="ml-1">
                        <h5 class="mb-0">
                            Outstanding
                        </h5>
                        <small>
                            {{ userData.outstanding_balance_formatted }}
                        </small>
                        </div>
                    </div>

                    <div class="d-flex align-items-center mr-2 mt-1" v-for="commodity in userData.commodity_balances" v-bind:key="commodity.name">
                        <b-avatar
                        variant="light-primary"
                        rounded
                        >
                        <feather-icon
                            icon="BoxIcon"
                            size="18"
                        />
                        </b-avatar>
                        <div class="ml-1">
                        <h5 class="mb-0">
                            {{ commodity.qty_balance_formatted }}
                        </h5>
                        <small>{{ commodity.commodity_name }} Balance</small>
                        </div>
                    </div>

                </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
                cols="12"
                xl="6"
            >
                <table class="mt-2 mt-xl-0 w-100">
                    <tr>
                    <td class="pb-50" colspan="2">
                        <span class="badge badge-success" v-if="userData.is_active">Online</span>
                        <span class="badge badge-secondary" v-else>Offline</span>
                        <small v-if="userData.last_active != null"><br>Last seen at {{ userData.last_active }}</small>
                    </td>
                </tr>
                <tr>
                    <th class="pb-50" width="30%">
                    <feather-icon
                        icon="CreditCardIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">ID</span>
                    </th>
                    <td class="pb-50">
                    {{ userData.id }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="CreditCardIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Code</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                    {{ userData.code != null ? userData.code : '-' }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="CreditCardIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">EPACT ID</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                    {{ userData.epact_vendor_id != null ? userData.epact_vendor_id : '-' }}
                    </td>
                </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                          icon="CreditCardIcon"
                          class="mr-75"
                      />
                      <span class="font-weight-bold">EPACT Email</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ userData.epact_email != null ? userData.epact_email : '-' }}
                    </td>
                  </tr>
                <tr>
                    <th>
                    <feather-icon
                        icon="PhoneIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Phone</span>
                    </th>
                    <td>
                    {{ userData.phone != null ? userData.phone : '-' }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="FlagIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Country</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                    {{ userData.country != null ? userData.country : '-' }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="FlagIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Province</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                    {{ userData.province != null ? userData.province : '-' }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="FlagIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">City</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                    {{ userData.city != null ? userData.city : '-' }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="MapPinIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Address</span>
                    </th>
                    <td class="pb-50">
                    {{ userData.address != null ? userData.address : '-' }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="UserIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">PIC Name</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                    {{ userData.pic_name != null ? userData.pic_name : '-' }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="UserCheckIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">PIC Position</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                    {{ userData.pic_position != null ? userData.pic_position : '-' }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="BookmarkIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Status</span>
                    </th>
                    <td class="pb-50">
                    <span class="badge text-capitalize badge-pill" v-bind:class="userData.approval_status == 'active' ? 'badge-light-success' : 'badge-light-secondary'">{{ userData.approval_status }}</span>
                    <span v-if="userData.approval_status == 'active' && userData.approved_at != null">
                        at {{ userData.approved_at }}
                    </span>
                    </td>
                </tr>
                <tr v-if="userData.suspend_note != null && userData.approval_status == 'suspend'">
                    <th class="pb-50">
                    <feather-icon
                        icon="ClockIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Suspend Note</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                    {{ userData.suspend_note != null ? userData.suspend_note : '-' }}
                    </td>
                </tr>
                <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="ClockIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Register At</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                    {{ userData.register_at != null ? userData.register_at : '-' }}
                    </td>
                </tr>
                <!-- <tr>
                    <th class="pb-50">
                    <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Roles</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                        <span v-if="userData.roles.length == 0" class="badge text-capitalize badge-light-primary badge-pill">User</span>
                        <span v-else v-for="role in userData.roles" :key="role" class="badge text-capitalize badge-light-success badge-pill">{{ role }}</span>
                    </td>
                </tr> -->
                </table>
            </b-col>
            </b-row>
        </b-card>
      </b-col>

      <b-col cols="12" lg="4">
          <b-card
            no-body
        >
            <b-card-body>
            <b-card-title>Restricted Commodity</b-card-title>
            <small>User have access to restricted commodity:</small>
            </b-card-body>
            <table class="table table-striped table-sm table-bordered table-hover" style="width: 100%">
                <tr>
                    <th>Commodity</th>
                    <th>See</th>
                    <th>Buy</th>
                    <th>Sell</th>
                </tr>
                <tr v-for="item in userData.access_to_restricted_commodity" v-bind:key="item.commodity.slug">
                    <td>{{ item.commodity.name }}</td>
                    <td>
                        <feather-icon
                            v-if="item.can_see"
                            icon="CheckIcon"
                            class="mr-75"
                        />
                        <feather-icon
                            v-else
                            icon="XIcon"
                            class="mr-75"
                        />
                    </td>
                    <td>
                        <feather-icon
                            v-if="item.can_buy"
                            icon="CheckIcon"
                            class="mr-75"
                        />
                        <feather-icon
                            v-else
                            icon="XIcon"
                            class="mr-75"
                        />
                    </td>
                    <td>
                        <feather-icon
                            v-if="item.can_sell"
                            icon="CheckIcon"
                            class="mr-75"
                        />
                        <feather-icon
                            v-else
                            icon="XIcon"
                            class="mr-75"
                        />
                    </td>
                </tr>
                <tr v-if="userData.access_to_restricted_commodity.length == 0">
                    <td colspan="4" class="text-center"><small>Data empty</small></td>
                </tr>
            </table>
        </b-card>
      </b-col>

      <b-col cols="12" lg="12" v-if="loadTransactionHistory">
          <b-card title="Transaction History">
            <b-tabs
            content-class="pt-1"
            >
                <b-tab
                    active
                    title="Cash Transaction History"
                >
                    <cash-transaction-table :user_id="userData.id" :hide-user="true"></cash-transaction-table>
                </b-tab>
                <b-tab
                    title="Commodity Transaction History"
                >
                    <commodity-transaction-table :user_id="userData.id"></commodity-transaction-table>
                </b-tab>
            </b-tabs>
        </b-card>
      </b-col>

  </b-row>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BCardBody, BCardTitle, BTabs, BTab, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import CashTransactionTable from './../components/CashTransactionTable.vue'
import CommodityTransactionTable from './../components/CommodityTransactionTable.vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Vue from 'vue';
import VuejsDialog from 'vuejs-dialog';
Vue.use(VuejsDialog);
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

export default {
    title () {
        return `Detail User`
    },
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BCardBody,
        BCardTitle,
        BTabs,
        BTab,
        BDropdown,
        BDropdownItem,
        CashTransactionTable,
        CommodityTransactionTable,
    },
    data() {
        return {
            id: this.$route.params.id,
            userData: Object,
            socketLoaded: false,
            loadTransactionHistory: true,
        }
    },
    setup(props) {
        return {
            checkPermission,
            successNotification,
            errorNotification,
        }
    },
    async created() {
        this.loadData();
    },
    methods: {
        loadData() {
            this.$http.get('/admin/users/' + this.id).then(response => {
                this.userData = response.data.data;

                // if (!this.socketLoaded) {
                //     var context = this

                //     this.$echo.channel('UserBalanceUpdate.' + this.userData.uuid).listen('.UserBalanceUpdate', (payload) => {
                //         this.loadData();

                //         this.loadTransactionHistory = false;
                //         this.$nextTick(() => {
                //             this.loadTransactionHistory = true;
                //         });
                //     });

                //     this.$echo.channel('UserOnlineStatus.' + this.userData.uuid).listen('.UserOnlineStatus', (payload) => {
                //         context.userData.is_active = payload.is_active
                //         context.userData.last_active = payload.last_active
                //     });

                //     this.socketLoaded = true;
                // }
            })
        },
        deleteItem(id) {

            this.$swal({
                title: 'Are you sure?',
                text: 'Are you sure to delete this user?',
                icon: 'warning',
                showCancelButton: true,
                customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {

                this.$http.delete('admin/users/' + id)
                .then(response => {


                    this.$swal({
                        icon: 'success',
                        title: 'Success!',
                        text: 'User successfully deleted',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                    this.$router.push({ name: 'users.index'})

                })
                .catch(error => {
                    if (error.response.data.meta.messages.length > 0) {
                        errorNotification(this, 'Oops!', error.response.data.meta.messages)
                    }
                })

                }
            })

        },
        resetPassword(id) {

      this.$swal({
        title: 'Are you sure?',
        text: 'System will generate password and send it to user email.',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

          this.$http.post('admin/users/' + id + '/reset-password')
          .then(response => {

            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Password has been updated and sent to user email!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
          .catch(error => {
            if (error.response.data.meta.messages.length > 0) {
              errorNotification(this, 'Oops!', error.response.data.meta.messages)
            }
          })

        }
      })

    },
    approveUser(id) {

        this.$swal({
            title: 'Are you sure?',
            text: 'System will send approval email and password to user.',
            icon: 'warning',
            showCancelButton: true,
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {

            this.$http.post('admin/users/' + id + '/approve')
            .then(response => {

                this.loadData()
                this.$swal({
                    icon: 'success',
                    title: 'Success!',
                    text: 'User successfully approved!',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })

            })
            .catch(error => {
                if (error.response.data.meta.messages.length > 0) {
                    errorNotification(this, 'Oops!', error.response.data.meta.messages)
                }
            })

            }
        })

    },
    rejectUser(id) {

        this.$dialog
        .prompt({
            title: "Reject User",
        }, {
            promptHelp: 'Enter reject reason here, system will send rejection email to user with this reason:'
        })
        .then(dialog => {

            this.$http.post('admin/users/' + id + '/reject', {
                reject_reason: dialog.data
            })
            .then(response => {

                successNotification(this, 'Success', 'User successfully rejected!')
                this.$router.push({ name: 'users.index'})

            })
            .catch(error => {
                if (error.response.data.meta.messages.length > 0) {
                    errorNotification(this, 'Oops!', error.response.data.meta.messages)
                }
            })

        })

    },
    suspend(id) {

        this.$dialog
        .prompt({
            title: "Suspend User",
        }, {
            promptHelp: 'Enter suspend note here:'
        })
        .then(dialog => {

            this.$http.post('admin/users/' + id + '/suspend', {
                suspend_note: dialog.data
            })
            .then(response => {

                successNotification(this, 'Success', 'User successfully suspended!')
                this.loadData()

            })
            .catch(error => {
                if (error.response.data.meta.messages.length > 0) {
                    errorNotification(this, 'Oops!', error.response.data.meta.messages)
                }
            })

        })

    },
    unsuspend(id) {

        this.$swal({
            title: 'Are you sure?',
            text: 'System will unsuspend this user.',
            icon: 'warning',
            showCancelButton: true,
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {

            this.$http.post('admin/users/' + id + '/unsuspend')
            .then(response => {

                this.loadData()
                this.$swal({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Action success!',
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                })

            })
            .catch(error => {
                if (error.response.data.meta.messages.length > 0) {
                    errorNotification(this, 'Oops!', error.response.data.meta.messages)
                }
            })

            }
        })

    },
    },
}
</script>

<style>

</style>
